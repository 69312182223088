<template>
  <div class="filter-page" @mouseleave="$root.showMascot($event)">
    <page-header
      v-observe-visibility="{
        callback: headerVisible,
        intersection: {
          rootMargin: '-71px',
        },
      }"
      dark-crumb
      light
      title="Подбор апартаментов"
      :link-to="{ name: 'MainPage' }"
    >
      <div slot="title-right" class="filter-page__sort filter-sort">
        <div
          class="filter-sort__selector"
          tabindex="1"
          @click="sortOpened = !sortOpened"
          @blur="sortOpened = false"
        >
          <span>{{ sortName }}: </span
          ><span class="filter-sort__current">{{ sortWay }}</span>
          <span
            :class="{
              'filter-sort__arrow': true,
              'filter-sort__arrow--down': sortOpened,
            }"
          >
            <svg
              width="10"
              height="5"
              viewBox="0 0 10 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M5 0L0 5H2.21429L5 2.21429L7.78571 5H10L5 0Z"
                fill="#18181B"
              />
            </svg>
          </span>
        </div>
        <ol
          :class="{
            'filter-sort__list': true,
            'filter-sort__list--opened': sortOpened,
          }"
        >
          <li class="filter-sort__item" @click="sortChange('amount', 'asc')">
            По цене: к большей
          </li>
          <li class="filter-sort__item" @click="sortChange('amount', 'desc')">
            По цене: к меньшей
          </li>
          <li class="filter-sort__item" @click="sortChange('area', 'asc')">
            По площади: к большей
          </li>
          <li class="filter-sort__item" @click="sortChange('area', 'desc')">
            По площади: к меньшей
          </li>
        </ol>
      </div>
    </page-header>
    <content-wrapper-block>
      <div class="filter-page__body filter-body">
        <div
          v-if="filterOpened"
          :class="{
            'filter-body__overlay': true,
            'filter-body__overlay--blurred': filterOpened,
          }"
          @click="filterOpened = false"
        />
        <div
          ref="filter"
          :class="{
            'filter-body__props': true,
            'filter-props': true,
            'filter-props--spy': filterSpy,
            'filter-props--opened': filterOpened,
          }"
        >
          <div
            class="filter-body__header filter-header"
            @click="filterOpened = !filterOpened"
          >
            <span class="filter-header__name">фильтр по параметрам</span>
            <span class="filter-header__selected"
              >(выбрано {{ filteredResult.length }})</span
            >
            <span class="gg-select" />
          </div>
          <div class="filter-props__item">
            <div class="filter-props__label">Планировки</div>
            <ui-tab-bar v-model="filter.quantity" as-block>
              <ui-tab-bar-tab
                v-for="quantity in quantities"
                :key="quantity.slug"
                :value="quantity.slug"
                :disabled="!quantity.enable"
                @click="click"
              >
                {{ quantity.name }}
              </ui-tab-bar-tab>
            </ui-tab-bar>
          </div>
          <div class="filter-props__item" style="display: none">
            <multiselect
              v-model="filter.planType"
              :options="planTypes"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :allow-empty="false"
              :clear-on-select="false"
              preselect-first
              placeholder="Тип"
            />
          </div>
          <div class="filter-props__item">
            <div class="filter-props__label">Этаж</div>
            <ui-range-input
              v-model="filter.floor"
              :from="floor.from"
              :to="floor.to"
              @change="changeFilter"
            />
          </div>
          <div class="filter-props__item">
            <div class="filter-props__label">Площадь</div>
            <ui-range-input
              v-model="filter.area"
              :from="area.from"
              :to="area.to"
              @change="changeFilter"
            />
          </div>
          <div class="filter-props__item">
            <div class="filter-props__label">Цена</div>
            <ui-range-input
              v-model="filter.price"
              :from="price.from"
              :to="price.to"
              @change="changeFilter"
            />
          </div>
          <div v-if="false" class="filter-props__item">
            <div sm="4" lg="12" class="filter-page__switch">
              <div class="select-plans-page-block__label">
                <span> Акция </span>
              </div>
              <checkbox-switch
                v-model="filter.with_decoration"
                class="filter-page__switch-button"
              />
            </div>
          </div>
          <div class="filter-props__item">
            <div class="filter-props__label">Вид отделки</div>
            <multiselect
              v-model="filter.decorationType"
              :options="decorationTypes"
              :searchable="false"
              close-on-select
              :show-labels="false"
              :allow-empty="false"
              :clear-on-select="false"
              label="label"
              preselect-first
              placeholder="Вид отделки"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.label }}
              </template>
            </multiselect>
          </div>
          <div class="filter-props__item">
            <span class="filter-props__selected"
              >Выбрано: <b>{{ filteredResult.length }}</b></span
            >
            <span class="filter-props__clear" @click="resetFilter"
              >Очистить фильтры</span
            >
          </div>
          <div v-if="filterOpened" class="filter-props__item">
            <!-- <ui-button @click="applyFilter">
              Применить
            </ui-button> -->
          </div>
        </div>
        <div class="filter-body__results">
          <div v-if="!filteredResult.length" class="filter-body__notification">
            Нет доступных предложений
          </div>
          <div
            v-for="(apartment, i) in filterSorted"
            :key="apartment.id"
            :style="{ order:
                apartment.properties.with_decoration_design ||
                apartment.properties.with_decoration_whitebox
                  ? 0
                  : (i + 1)
            }"
            class="apartment-list__item-wrapper"
          >
            <router-link
              class="filter-body__link"
              :to="{
                name: 'ApartmentPage',
                params: {
                  korp: apartment.korpus,
                  sec: apartment.section,
                  floor: apartment.floor,
                  fnumb: apartment.number_on_floor,
                  numb: apartment.number,
                  quantity: getQuantity(apartment.quantity),
                  id: apartment.id,
                },
              }"
            >
              <apartment-card
                v-if="getPlanImage(apartment, true)"
                class="filter-body__result"
                :plan-image="getPlanImage(apartment, true)"
                :area="apartment.area"
                :rooms="apartment.quantity"
                :floor="apartment.floor"
                :number="apartment.number"
                :korpus="apartment.section[0]"
                :cost="apartment.properties.old_cost || apartment.amount"
                :new-cost="apartment.discount || apartment.properties.old_cost ? apartment.discount_cost : undefined"
                :status="apartment.status"
                :price="setMortgagePay(apartment, storage.mortgage)"
                :alternative="apartment.alternative"
                :properties="apartment.properties"
                full
              />
            </router-link>
          </div>
          <div
            v-for="(banner, i) in banners"
            :key="i"
            :style="{ order: (i + 1) * 3 }"
            class="apartment-list__item-wrapper"
          >
            <banner-card
              class="filter-body__banner"
              :image="banner.image.image_thumb"
              :title="banner.title"
              :text="banner.description"
              :agreement-text="banner.agreement_text"
              :modal-title="banner.form_name"
              :button-title="banner.text_button"
            />
          </div>
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import { helpers } from "@osnova-adv/ms3-blocks";
import PageHeader from "@/components/elements/PageHeader";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import ApartmentCard from "@/components/elements/ApartmentCard";
import UiTabBar from "@/components/ui/UITabBar";
import UiTabBarTab from "@/components/ui/UITabBarTab";
import UiRangeInput from "@/components/ui/UIRangeInput";
import BannerCard from "@/components/elements/BannerCard";
import CheckboxSwitch from "@/components/elements/CheckboxSwitch";

const DECORATION_TYPES = [
  { value: '', label: 'Не выбран' },
  { value: 'with_decoration', label: 'Возможна отделка' },
  // { value: 'with_decoration_whitebox', label: 'White Box' }
];

export default {
  name: "FilterPage",
  components: {
    BannerCard,
    UiRangeInput,
    UiTabBarTab,
    UiTabBar,
    ApartmentCard,
    ContentWrapperBlock,
    PageHeader,
    CheckboxSwitch,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Большой выбор апартаментов и планировочных решений в новом жилом комплексе бизнес-класса.",
      },
    ],
  },
  data: () => ({
    render: true,
    alternative: false,
    sortKey: "amount",
    sortOrder: "asc",
    sortOpened: false,
    filterOpened: false,
    filterSpy: false,
    freeOnly: true,
    filtered: [],
    filteredResult: [],
    updateQuery: false,
    with_decoration: false,
    decorationTypes: DECORATION_TYPES,
    floor: {
      from: 0,
      to: 1,
    },
    price: {
      from: 0,
      to: 1,
    },
    area: {
      from: 0,
      to: 1,
    },
    filter: {
      with_decoration: false,
      planType: "all",
      decorationType: DECORATION_TYPES[0],
      quantity: "",
      floor: {
        from: 0,
        to: 1,
      },
      price: {
        from: 0,
        to: 1,
      },
      area: {
        from: 0,
        to: 1,
      },
    },
  }),
  watch: {
    filterOpened() {
      if (this.filterOpened === true) {
        document.body.style = "overflow: hidden;";
      } else {
        document.body.style = "";
      }
    },
    apartmentsLoaded() {
      this.updateQuery = false;
      this.loadApartments();
    },
    with_decoration() {
      this.setNewApartmentsDecor(this.with_decoration);
    },
    newApartmentsDecor() {
      this.with_decoration = this.newApartmentsDecor;
      this.filter.with_decoration = this.newApartmentsDecor;
    },
    "filter.action": {
      handler() {
        this.updateFiltered(true);
        this.updateFilterValues();
      },
    },
    "filter.planType": {
      handler() {
        this.updateFiltered(true);
        this.updateFilterValues();
      },
    },
    'filter.decorationType': {
      handler() {
        this.changeFilter();
        this.updateFiltered();
      },
      deep: true
    },
    "filter.quantity": {
      handler() {
        this.updateFiltered(true);
        this.updateFilterValues();
        // this.applyFilter()
        this.$nextTick(() => {
          if (!this.quantities.find((item) => item.enable)) {
            this.filter.planType = "all";
          }
        });
      },
    },
    "filter.floor": {
      deep: true,
      handler() {
        this.updateFiltered();
      },
    },
    "filter.with_decoration": {
      deep: true,
      handler() {
        this.setNewApartmentsDecor(this.filter.with_decoration);
        this.updateFiltered();
      },
    },
    "filter.price": {
      deep: true,
      handler() {
        this.updateFiltered();
      },
    },
    "filter.area": {
      deep: true,
      handler() {
        this.updateFiltered();
      },
    },
  },
  created() {
    this.quantity = 0;
    this.$nextTick(() => {
      this.loadApartments();
      // this.$loadTrackScript();
    });
  },
  computed: {
    ...mapState({
      apartments: (state) => state.apartments,
      apartmentsLoaded: (state) => state.apartmentsLoaded,
      alternativePlans: (state) => state.alternativePlans,
      minPercent: (state) => state.minPercent,
      storage: (state) => state.storage,
    }),
    ...mapGetters({
      newApartmentsDecor: "newApartmentsDecor",
      mortgageProps: "GET_MORTGAGE_PROPS"
    }),
    banners() {
      const banners = this.storage.promo.banners.filter((item) => item.slug)
      if (banners) {
        return banners.filter(item => item.activity)
      }
      return [];
    },
    sortName() {
      switch (this.sortKey) {
        case "amount":
          return "По цене";
        case "area":
          return "По площади";
      }

      return "";
    },
    sortWay() {
      switch (this.sortOrder) {
        case "desc":
          return "к меньшей";
        case "asc":
          return "к большей";
      }

      return "";
    },
    quantities() {
      const result = [];
      this.apartments.forEach((apartment) => {
        if (
          !result.find(
            (quantity) =>
              quantity.slug === apartment.quantity.replace("Евро-", "")
          )
        ) {
          result.push({
            slug: apartment.quantity.replace("Евро-", ""),
            name:
              apartment.quantity === "Студия"
                ? "Ст"
                : apartment.quantity.replace("Евро-", "") + "К",
            enable:
              this.filtered.find((item) => {
                return (
                  item.quantity !== undefined &&
                  item.quantity.replace("Евро-", "") ===
                    apartment.quantity.replace("Евро-", "")
                );
              }) !== undefined ||
              this.filter.planType === "all" ||
              this.filter.planType === null,
          });
        }
      });

      return result.sort((a, b) => {
        if (b.slug === "Студия") return 1;
        return parseInt(a.slug) > parseInt(b.slug) ? 1 : -1;
      });
    },
    planTypes() {
      const types = [];
      const aparts = this.getFiltered(true, true);

      types.push("all");

      aparts.forEach((apartment) => {
        if (!apartment.plan_type) return false;

        if (!types.find((type) => type === apartment.plan_type)) {
          types.push(apartment.plan_type);
        }
      });

      return types;
    },
    filterSorted() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.render = false;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const result = this.filteredResult.sort((a, b) => {
        switch (this.sortOrder) {
          case "asc":
            return parseFloat(a[this.sortKey]) > parseFloat(b[this.sortKey])
              ? 1
              : -1;
          case "desc":
            return parseFloat(a[this.sortKey]) < parseFloat(b[this.sortKey])
              ? 1
              : -1;
        }
      });

      const hitted = [...result];

      this.$nextTick(() => {
        this.render = true;
      });

      return hitted;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.fullPath.indexOf("apartments") >= 0) {
        vm.$nextTick(() => {
          vm.loadApartments();
          vm.instanceFilter();
        });
      }
      vm.setMascotFlag(true);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.setMascotFlag(false);
    next();
  },
  methods: {
    ...mapMutations({
      setNewApartmentsDecor: "SET_NEW_APARTMENTS_DECOR",
      setMascotFlag: "SET_MASCOT_FLAG",
    }),
    instanceFilter() {
      this.$nextTick(() => {
        this.filter.with_decoration = false;
        if (this.$route.meta.defaultFilter) {
          const defaultFilter = this.$route.meta.defaultFilter;
          const keys = Object.keys(defaultFilter);
          keys.forEach((item) => {
            this.filter[item] = defaultFilter[item];
          });
        }
        this.with_decoration = this.newApartmentsDecor;
        this.filter.with_decoration = this.newApartmentsDecor;
      });
    },
    setMortgagePay (apartment, mortgage) {
      return helpers.setMortgagePay(apartment, mortgage)
    },
    changeFilter() {
      if (this.filterOpened === false) {
        return this.applyFilter();
      }
    },
    click() {
      this.filter = {
        action: 0,
        planType: "all",
        decorationType: this.filter.decorationType,
        quantity: [],
        with_decoration: this.filter.with_decoration,
        floor: {
          from: this.floorFrom,
          to: this.floorTo,
        },
        price: {
          from: this.priceFrom,
          to: this.priceTo,
        },
        area: {
          from: this.areaFrom,
          to: this.areaTo,
        },
      };
      // if (this.filteredResult.length > 0) {
      //   this.quantities()
      // }
    },
    loadApartments() {
      if (!this.apartmentsLoaded) return;

      this.updateQuery = false;
      this.filterFromQuery();
      this.updateFiltered(true);

      this.updateFilterValues(true);
      // this.applyFilter()
      this.updateQuery = true;
    },
    updateFiltered(ignoreValues = false) {
      const result = this.getFiltered(ignoreValues);
      // this.filterToQuery()
      this.filteredResult = result;
    },
    updateFilterValues(isFirstOpen = false) {
      const area = { from: 0, to: 1 };
      const price = { from: 0, to: 1 };
      const floor = { from: 0, to: 1 };

      if (this.apartments.length >= 1) {
        price.to = 0;
        price.from = 900000000;
        area.to = 0;
        area.from = 999;
        floor.to = 0;
        floor.from = 10000;
      }

      if (this.filter.quantity.length === 0 || this.filter.quantity === "") {
        this.apartments.forEach((apartment) => {
          if (!this.filter.quantity) {
            let resultHaveDecorations = false;
            // if (apartment.with_decoration) {
            //   resultHaveDecorations = true;
            //   return;
            // }
            this.resultHaveDecorations = resultHaveDecorations;
            if (floor.from > parseInt(apartment.floor)) {
              floor.from = parseInt(apartment.floor);
            }
            if (floor.to < parseInt(apartment.floor)) {
              floor.to = parseInt(apartment.floor);
            }
            // вставляет минимальюную и максимальную цену
            if (price.from > parseInt(apartment.discount_cost)) {
              price.from = parseInt(apartment.discount_cost);
            }
            if (price.to < parseInt(apartment.discount_cost)) {
              price.to = parseInt(apartment.discount_cost);
            }
            if (area.from > parseInt(apartment.area)) {
              area.from = parseInt(apartment.area);
            }
            if (area.to < parseInt(apartment.area)) {
              area.to = parseInt(apartment.area);
            }
          }
        });
      } else {
        this.filteredResult.forEach((apartment) => {
          if (
            floor.from > parseInt(apartment.floor) &&
            (apartment.quantity === "Студия"
              ? "Студия"
              : apartment.quantity.replace("Евро-", "")) ===
              this.filter.quantity
          ) {
            floor.from = parseInt(apartment.floor);
          }
          if (
            floor.to < parseInt(apartment.floor) &&
            (apartment.quantity === "Студия"
              ? "Студия"
              : apartment.quantity.replace("Евро-", "")) ===
              this.filter.quantity
          ) {
            floor.to = parseInt(apartment.floor);
          }
          // вставляет минимальюную и максимальную цену
          if (
            price.from > parseInt(apartment.discount_cost) &&
            (apartment.quantity === "Студия"
              ? "Студия"
              : apartment.quantity.replace("Евро-", "")) ===
              this.filter.quantity
          ) {
            price.from = parseInt(apartment.discount_cost);
          }
          if (
            price.to < parseInt(apartment.discount_cost) &&
            (apartment.quantity === "Студия"
              ? "Студия"
              : apartment.quantity.replace("Евро-", "")) ===
              this.filter.quantity
          ) {
            price.to = parseInt(apartment.discount_cost);
          }
          if (
            area.from > parseInt(apartment.area) &&
            (apartment.quantity === "Студия"
              ? "Студия"
              : apartment.quantity.replace("Евро-", "")) ===
              this.filter.quantity
          ) {
            area.from = parseInt(apartment.area);
          }
          if (
            area.to < parseInt(apartment.area) &&
            (apartment.quantity === "Студия"
              ? "Студия"
              : apartment.quantity.replace("Евро-", "")) ===
              this.filter.quantity
          ) {
            area.to = parseInt(apartment.area);
          }
        });
      }

      this.floor.from = floor.from;
      this.floor.to = floor.to;
      this.filter.floor.from = floor.from;
      this.filter.floor.to = floor.to;

      this.area.from = area.from;
      this.area.to = area.to;
      this.filter.area.from = area.from;
      this.filter.area.to = area.to;

      this.price.from = price.from;
      this.price.to = price.to;
      // this.$set(this.price, 'from', price.from)
      this.filter.price.from = price.from;
      this.filter.price.to = price.to;

      const query = {
        quantity: this.quantityRewriter(this.filter.quantity),

        floorFrom: this.filter.floor.from,
        floorTo: this.filter.floor.to,

        areaFrom: this.filter.area.from,
        areaTo: this.filter.area.to,

        priceFrom: this.filter.price.from,
        priceTo: this.filter.price.to,
        decorationType: this.filter.decorationType.value,
      };
      const theQuery = this.$route.query;
      if (this.$route.meta.keepGetParams) {
        return;
      }
      if (!isFirstOpen && theQuery.quantity !== query.quantity) {
        this.$router.replace({ query: query });
      }
    },
    filterToQuery() {
      const theQuery = this.$route.query;
      let query = {
        quantity: this.filter.quantity,

        floorFrom: this.filter.floor.from,
        floorTo: this.filter.floor.to,

        areaFrom: this.filter.area.from,
        areaTo: this.filter.area.to,

        priceFrom: this.filter.price.from,
        priceTo: this.filter.price.to,
        decorationType: this.filter.decorationType.value,
      };

      const defaultFilter = {
        quantity: "",
        floorFrom: 0,
        floorTo: 1,
        priceFrom: 0,
        priceTo: 1,
        areaFrom: 0,
        areaTo: 1,
        decorationType: this.decorationTypes[0].value,
      };

      for (const key in query) {
        if (query[key] === defaultFilter[key] && theQuery[key]) {
          query[key] = theQuery[key];
        }
      }

      query = { ...theQuery, ...query };
      const keys = Object.keys(theQuery);
      keys.forEach((item) => {
        if (theQuery[item] !== String(query[item])) {
          // если хоть по одному ключу запросы не совпали
          this.$router.replace({ query: query }); // то можно перенаправит пользователя по новому урлу
          return true;
        }
      });
    },

    filterFromQuery() {
      this.updateQuery = false;
      if (this.$route.query.quantity) {
        this.filter.quantity = this.quantityRewriter(
          this.$route.query.quantity
        );
      }
      this.$nextTick(() => {
        this.filter.floor.from =
          parseInt(this.$route.query.floorFrom) || this.floor.from;
        this.filter.floor.to =
          parseInt(this.$route.query.floorTo) || this.floor.to;
        this.filter.area.from =
          parseInt(this.$route.query.areaFrom) || this.area.from;
        this.filter.area.to =
          parseInt(this.$route.query.areaTo) || this.area.to;
        this.filter.price.from =
          parseInt(this.$route.query.priceFrom) || this.price.from;
        this.filter.price.to =
          parseInt(this.$route.query.priceTo) || this.price.to;
        if (this.$route.query.decorationType) {
          this.filter.decorationType = this.decorationTypes.find(({ value }) => value === this.$route.query.decorationType)
        }
      });

      // this.$nextTick(() => {
      //   this.updateQuery = true
      // })
    },
    getFiltered(ignoreValues = false, ignorePlanTypes = false) {
      this.apartments.filter((e) => {
        for (const key in this.alternativePlans) {
          const numPlans = this.alternativePlans[key].name;
          for (let i = 0; i < numPlans.length; i++) {
            if (e.number === numPlans[i]) {
              e.alternative = true;
            }
          }
        }
      });
      return this.apartments.filter((apartment) => {
        return (
          (ignoreValues ||
            ((apartment.floor >= this.filter.floor.from ||
              !this.filter.floor.from) &&
              (apartment.floor <= this.filter.floor.to ||
                !this.filter.floor.to))) &&
          (ignoreValues ||
            ((parseInt(apartment.discount_cost) >= this.filter.price.from ||
              !this.filter.price.from) &&
              (parseInt(apartment.discount_cost) <= this.filter.price.to ||
                !this.filter.price.to))) &&
          (ignoreValues ||
            ((parseInt(apartment.area) >= this.filter.area.from ||
              !this.filter.area.from) &&
              (parseInt(apartment.area) <= this.filter.area.to ||
                !this.filter.area.to))) &&
          (this.filter.quantity.length === 0 ||
            this.filter.quantity.indexOf(
              apartment.quantity.replace("Евро-", "")
            ) > -1) &&
          (this.filter.planType === "all" ||
            ignorePlanTypes ||
            this.filter.planType === apartment.plan_type) &&
          (ignoreValues ||
            !this.filter.with_decoration ||
            apartment.with_decoration == this.filter.with_decoration) &&
          (ignoreValues || !this.filter.decorationType.value || apartment.properties[this.filter.decorationType.value])
        );
      });
    },
    resetFilter() {
      this.filtered = this.apartments;
      this.filterOpened = false;
      (this.with_decoration = false),
        (this.filter = {
          action: 0,
          planType: "all",
          decorationType: this.decorationTypes[0],
          quantity: "",
          with_decoration: false,
          floor: {
            from: this.floorFrom,
            to: this.floorTo,
          },
          price: {
            from: this.priceFrom,
            to: this.priceTo,
          },
          area: {
            from: this.areaFrom,
            to: this.areaTo,
          },
        });

      this.updateFilterValues();
    },
    applyFilter() {
      this.$nextTick(() => {
        this.filterOpened = false;
        this.filtered = this.filteredResult;
        // this.$router.replace()
        if (this.$route.meta.keepGetParams) {
          return;
        }
        this.filterToQuery();
      });
    },
    headerVisible(isVisible, entry) {
      this.filterSpy = !isVisible;
    },
    sortChange(key, order) {
      this.sortKey = key;
      this.sortOrder = order;
    },
    quantityRewriter(prop) {
      return prop === "S" ? "Студия" : prop === "Студия" ? "S" : prop;
    },
  },
};
</script>

<style lang="scss">
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.filter-props {
  .ui-tab {
    padding: 10px 6px 10px;
    flex: 1;
  }
}
.filter-page {
  .page-title__header {
    font-size: 30px;
    @media screen and (min-width: 868px) {
      font-size: 48px;
    }
  }
  .page-header {
    background-color: #f4f4f4 !important;
    padding-bottom: 0;

    &__top {
      flex-direction: column;
      align-items: flex-start;

      @media screen and (min-width: 868px) {
        flex-direction: row;
        align-items: center;
      }
    }

    &__top-panel {
      margin-left: 0;
      flex: 1;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      height: 90px;

      @media screen and (min-width: $screen-xs) {
        height: 80px;
      }

      @media screen and (min-width: $screen-sm) {
        margin-left: 40px;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.filter-page {
  background-color: #f4f4f4;
  min-height: 90vh;
  .hit2-text {
    @media screen and (min-width: $screen-sm) {
      font-size: 90%;
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }
  }
  &__switch {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &-button {
      position: relative;
      bottom: 5px;
    }
  }
  &__value {
    position: absolute;
    right: 25px;
    bottom: 18px;
    @media screen and (min-width: 768px) {
      bottom: 25px;
    }
  }
  &__sort,
  .filter-sort {
    font-family: $font-family-rubik;
    font-size: 12px;
    line-height: 115%;
    color: #2e2a2b;
    font-weight: 400;
    position: relative;
    margin-top: 60px;

    @media screen and (min-width: 868px) {
      margin-top: 0;
    }

    &__arrow {
      height: 10px;
      width: 10px;
      display: inline-flex;
      align-items: center;
      transition: all 100ms ease-out;
      transform: rotate(180deg);

      &--down {
        transform: rotate(0deg);
      }
    }

    &__current {
      font-weight: 300;
      margin-right: 4px;
    }
    &__selector {
      cursor: pointer;
      outline: none;

      &:hover {
        text-decoration: underline;
      }
    }
    &__list {
      z-index: 1;
      position: absolute;
      top: 25px;
      left: 0;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 180px;
      background: #f9f9f9;
      box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);
      max-height: 0;
      transition: all 300ms ease-out;
      overflow: hidden;

      @media screen and (min-width: $screen-sm) {
        right: 0;
        left: unset;
      }

      &--opened {
        max-height: 400px;
      }
    }
    &__item {
      padding: 12px 20px;
      cursor: pointer;
      transition: all 300ms ease-out;
      font-weight: 300;

      &:hover {
        color: #e14313;
      }
    }
  }

  &__body,
  .filter-body {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 868px) {
      flex-direction: row;
    }

    &__notification {
      text-align: center;
      padding: 20px;
      font-size: 24px;
      margin-bottom: 60px;
      opacity: 0.7;

      @media screen and (min-width: $screen-mini) {
        font-size: 32px;
      }
    }

    &__overlay {
      background: rgba(36, 36, 36, 0.5);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(0px);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      transition: all 1300ms ease-out;

      &--blurred {
        backdrop-filter: blur(10px);
      }

      @media screen and (min-width: 868px) {
        display: none;
      }
    }

    &__header,
    .filter-header {
      background-color: #18181b;
      padding: 11px 13px 13px 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      @media screen and (min-width: 868px) {
        display: none;
      }

      &__name {
        color: #ffffff;
        font-family: $font-family-bebas;
        font-size: 12px;
      }

      &__selected {
        font-family: $font-family-rubik;
        font-size: 10px;
        color: #ffffff;
        opacity: 0.5;
        margin-left: 10px;
      }
      .gg-select {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(var(--ggs, 1));
        width: 22px;
        height: 22px;
      }
      .gg-select::after,
      .gg-select::before {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 8px;
        height: 8px;
        left: 7px;
        transform: rotate(-45deg);
        color: #8b8b8d;
      }
      .gg-select::before {
        border-left: 2px solid;
        border-bottom: 2px solid;
        bottom: 9px;
      }
    }

    &__props,
    .filter-props {
      $left: 15px;

      top: 190px;
      left: $left;
      right: $left;
      width: auto;
      box-sizing: border-box;
      padding: 0 25px 15px 25px;
      background: #18181b;
      box-shadow: 6px 8px 16px rgba(0, 0, 0, 0.1);
      color: #ffffff;
      height: max-content;
      z-index: 1;
      position: absolute;
      max-height: 40px;
      overflow: hidden;
      transition: all 300ms ease-out;

      @media (min-width: $screen-xs) {
        top: 225px;
      }

      @media (min-width: $screen-sm) {
        top: 240px;
      }

      @media screen and (min-width: 868px) {
        width: 295px;
        left: unset;
        right: unset;
        position: sticky;
        overflow: visible;
        margin: 0;
        padding: 25px 20px;
        background: #2e2a2b;
        max-height: unset;
        top: unset;
      }

      &--spy {
        position: sticky;
        top: 93%;

        @media screen and (min-width: $screen-sm) {
          position: sticky;
          top: 75px;
        }
      }

      &--opened {
        max-height: 800px;
        z-index: 5;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 50px;
        max-height: calc(100vh - 50px);
        overflow-y: auto;

        @media screen and (min-width: $screen-xs) {
          top: 70px;
          max-height: calc(100vh - 70px);
        }

        @media screen and (min-width: 868px) {
          max-height: unset;
          left: unset;
          right: unset;
          bottom: unset;
        }
        .gg-select::before {
          display: none;
        }
        .gg-select::after {
          border-right: 2px solid;
          border-top: 2px solid;
          top: 9px;
        }
      }

      &__selected {
        font-size: 8px;
        line-height: 132.5%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ffffff;
        opacity: 0.5;
        margin-right: 12px;
      }

      &__clear {
        font-family: $font-family-rubik;
        font-weight: 300;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.03em;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      &__item {
        margin-bottom: 20px;
        .multiselect__tags {
          border: 1px solid #f5f5f5;
          background: #f5f5f5;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &__label {
        font-family: $font-family-bebas;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 132.5%;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 5px;
      }
    }

    &__banner {
      margin-bottom: 20px;
    }

    &__result {
      margin-bottom: 20px;
    }

    &__link {
      text-decoration: none;
    }

    &__results {
      display: grid;
      flex: 1;
      margin-left: 0;
      margin-top: 0;

      @media screen and (min-width: 868px) {
        margin-left: 35px;
        margin-top: 0;
      }
    }
  }

  ::v-deep {
    .content-wrapper {
      overflow: visible;
    }
  }
}
</style>
