var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "filter-page",
      on: {
        mouseleave: function ($event) {
          return _vm.$root.showMascot($event)
        },
      },
    },
    [
      _c(
        "page-header",
        {
          directives: [
            {
              name: "observe-visibility",
              rawName: "v-observe-visibility",
              value: {
                callback: _vm.headerVisible,
                intersection: {
                  rootMargin: "-71px",
                },
              },
              expression:
                "{\n      callback: headerVisible,\n      intersection: {\n        rootMargin: '-71px',\n      },\n    }",
            },
          ],
          attrs: {
            "dark-crumb": "",
            light: "",
            title: "Подбор апартаментов",
            "link-to": { name: "MainPage" },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "filter-page__sort filter-sort",
              attrs: { slot: "title-right" },
              slot: "title-right",
            },
            [
              _c(
                "div",
                {
                  staticClass: "filter-sort__selector",
                  attrs: { tabindex: "1" },
                  on: {
                    click: function ($event) {
                      _vm.sortOpened = !_vm.sortOpened
                    },
                    blur: function ($event) {
                      _vm.sortOpened = false
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.sortName) + ": ")]),
                  _c("span", { staticClass: "filter-sort__current" }, [
                    _vm._v(_vm._s(_vm.sortWay)),
                  ]),
                  _c(
                    "span",
                    {
                      class: {
                        "filter-sort__arrow": true,
                        "filter-sort__arrow--down": _vm.sortOpened,
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "10",
                            height: "5",
                            viewBox: "0 0 10 5",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              opacity: "0.5",
                              d: "M5 0L0 5H2.21429L5 2.21429L7.78571 5H10L5 0Z",
                              fill: "#18181B",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "ol",
                {
                  class: {
                    "filter-sort__list": true,
                    "filter-sort__list--opened": _vm.sortOpened,
                  },
                },
                [
                  _c(
                    "li",
                    {
                      staticClass: "filter-sort__item",
                      on: {
                        click: function ($event) {
                          return _vm.sortChange("amount", "asc")
                        },
                      },
                    },
                    [_vm._v(" По цене: к большей ")]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "filter-sort__item",
                      on: {
                        click: function ($event) {
                          return _vm.sortChange("amount", "desc")
                        },
                      },
                    },
                    [_vm._v(" По цене: к меньшей ")]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "filter-sort__item",
                      on: {
                        click: function ($event) {
                          return _vm.sortChange("area", "asc")
                        },
                      },
                    },
                    [_vm._v(" По площади: к большей ")]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "filter-sort__item",
                      on: {
                        click: function ($event) {
                          return _vm.sortChange("area", "desc")
                        },
                      },
                    },
                    [_vm._v(" По площади: к меньшей ")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c("content-wrapper-block", [
        _c("div", { staticClass: "filter-page__body filter-body" }, [
          _vm.filterOpened
            ? _c("div", {
                class: {
                  "filter-body__overlay": true,
                  "filter-body__overlay--blurred": _vm.filterOpened,
                },
                on: {
                  click: function ($event) {
                    _vm.filterOpened = false
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              ref: "filter",
              class: {
                "filter-body__props": true,
                "filter-props": true,
                "filter-props--spy": _vm.filterSpy,
                "filter-props--opened": _vm.filterOpened,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "filter-body__header filter-header",
                  on: {
                    click: function ($event) {
                      _vm.filterOpened = !_vm.filterOpened
                    },
                  },
                },
                [
                  _c("span", { staticClass: "filter-header__name" }, [
                    _vm._v("фильтр по параметрам"),
                  ]),
                  _c("span", { staticClass: "filter-header__selected" }, [
                    _vm._v(
                      "(выбрано " + _vm._s(_vm.filteredResult.length) + ")"
                    ),
                  ]),
                  _c("span", { staticClass: "gg-select" }),
                ]
              ),
              _c(
                "div",
                { staticClass: "filter-props__item" },
                [
                  _c("div", { staticClass: "filter-props__label" }, [
                    _vm._v("Планировки"),
                  ]),
                  _c(
                    "ui-tab-bar",
                    {
                      attrs: { "as-block": "" },
                      model: {
                        value: _vm.filter.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "quantity", $$v)
                        },
                        expression: "filter.quantity",
                      },
                    },
                    _vm._l(_vm.quantities, function (quantity) {
                      return _c(
                        "ui-tab-bar-tab",
                        {
                          key: quantity.slug,
                          attrs: {
                            value: quantity.slug,
                            disabled: !quantity.enable,
                          },
                          on: { click: _vm.click },
                        },
                        [_vm._v(" " + _vm._s(quantity.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "filter-props__item",
                  staticStyle: { display: "none" },
                },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.planTypes,
                      searchable: false,
                      "close-on-select": true,
                      "show-labels": false,
                      "allow-empty": false,
                      "clear-on-select": false,
                      "preselect-first": "",
                      placeholder: "Тип",
                    },
                    model: {
                      value: _vm.filter.planType,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "planType", $$v)
                      },
                      expression: "filter.planType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-props__item" },
                [
                  _c("div", { staticClass: "filter-props__label" }, [
                    _vm._v("Этаж"),
                  ]),
                  _c("ui-range-input", {
                    attrs: { from: _vm.floor.from, to: _vm.floor.to },
                    on: { change: _vm.changeFilter },
                    model: {
                      value: _vm.filter.floor,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "floor", $$v)
                      },
                      expression: "filter.floor",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-props__item" },
                [
                  _c("div", { staticClass: "filter-props__label" }, [
                    _vm._v("Площадь"),
                  ]),
                  _c("ui-range-input", {
                    attrs: { from: _vm.area.from, to: _vm.area.to },
                    on: { change: _vm.changeFilter },
                    model: {
                      value: _vm.filter.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "area", $$v)
                      },
                      expression: "filter.area",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "filter-props__item" },
                [
                  _c("div", { staticClass: "filter-props__label" }, [
                    _vm._v("Цена"),
                  ]),
                  _c("ui-range-input", {
                    attrs: { from: _vm.price.from, to: _vm.price.to },
                    on: { change: _vm.changeFilter },
                    model: {
                      value: _vm.filter.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "price", $$v)
                      },
                      expression: "filter.price",
                    },
                  }),
                ],
                1
              ),
              false
                ? _c("div", { staticClass: "filter-props__item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "filter-page__switch",
                        attrs: { sm: "4", lg: "12" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "select-plans-page-block__label" },
                          [_c("span", [_vm._v(" Акция ")])]
                        ),
                        _c("checkbox-switch", {
                          staticClass: "filter-page__switch-button",
                          model: {
                            value: _vm.filter.with_decoration,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter, "with_decoration", $$v)
                            },
                            expression: "filter.with_decoration",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "filter-props__item" },
                [
                  _c("div", { staticClass: "filter-props__label" }, [
                    _vm._v("Вид отделки"),
                  ]),
                  _c("multiselect", {
                    attrs: {
                      options: _vm.decorationTypes,
                      searchable: false,
                      "close-on-select": "",
                      "show-labels": false,
                      "allow-empty": false,
                      "clear-on-select": false,
                      label: "label",
                      "preselect-first": "",
                      placeholder: "Вид отделки",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "singleLabel",
                        fn: function ({ option }) {
                          return [_vm._v(" " + _vm._s(option.label) + " ")]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.filter.decorationType,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "decorationType", $$v)
                      },
                      expression: "filter.decorationType",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "filter-props__item" }, [
                _c("span", { staticClass: "filter-props__selected" }, [
                  _vm._v("Выбрано: "),
                  _c("b", [_vm._v(_vm._s(_vm.filteredResult.length))]),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "filter-props__clear",
                    on: { click: _vm.resetFilter },
                  },
                  [_vm._v("Очистить фильтры")]
                ),
              ]),
              _vm.filterOpened
                ? _c("div", { staticClass: "filter-props__item" })
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            { staticClass: "filter-body__results" },
            [
              !_vm.filteredResult.length
                ? _c("div", { staticClass: "filter-body__notification" }, [
                    _vm._v(" Нет доступных предложений "),
                  ])
                : _vm._e(),
              _vm._l(_vm.filterSorted, function (apartment, i) {
                return _c(
                  "div",
                  {
                    key: apartment.id,
                    staticClass: "apartment-list__item-wrapper",
                    style: {
                      order:
                        apartment.properties.with_decoration_design ||
                        apartment.properties.with_decoration_whitebox
                          ? 0
                          : i + 1,
                    },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "filter-body__link",
                        attrs: {
                          to: {
                            name: "ApartmentPage",
                            params: {
                              korp: apartment.korpus,
                              sec: apartment.section,
                              floor: apartment.floor,
                              fnumb: apartment.number_on_floor,
                              numb: apartment.number,
                              quantity: _vm.getQuantity(apartment.quantity),
                              id: apartment.id,
                            },
                          },
                        },
                      },
                      [
                        _vm.getPlanImage(apartment, true)
                          ? _c("apartment-card", {
                              staticClass: "filter-body__result",
                              attrs: {
                                "plan-image": _vm.getPlanImage(apartment, true),
                                area: apartment.area,
                                rooms: apartment.quantity,
                                floor: apartment.floor,
                                number: apartment.number,
                                korpus: apartment.section[0],
                                cost:
                                  apartment.properties.old_cost ||
                                  apartment.amount,
                                "new-cost":
                                  apartment.discount ||
                                  apartment.properties.old_cost
                                    ? apartment.discount_cost
                                    : undefined,
                                status: apartment.status,
                                price: _vm.setMortgagePay(
                                  apartment,
                                  _vm.storage.mortgage
                                ),
                                alternative: apartment.alternative,
                                properties: apartment.properties,
                                full: "",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _vm._l(_vm.banners, function (banner, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "apartment-list__item-wrapper",
                    style: { order: (i + 1) * 3 },
                  },
                  [
                    _c("banner-card", {
                      staticClass: "filter-body__banner",
                      attrs: {
                        image: banner.image.image_thumb,
                        title: banner.title,
                        text: banner.description,
                        "agreement-text": banner.agreement_text,
                        "modal-title": banner.form_name,
                        "button-title": banner.text_button,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }